<template>
  <ion-page>
    <Header title="Notifikasi" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list class="notification">
        <ion-item v-for="h in notifications" :key="h.id" button @click="read(h)">
          <ion-label :class="{'read': h.read_at}">
            <div class="sub-label mb-05">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#DDDDDD"/>
                <g clip-path="url(#clip0)">
                <path d="M14.8799 10.08C14.8799 9.31616 14.5765 8.58362 14.0364 8.04351C13.4963 7.50341 12.7638 7.19998 11.9999 7.19998C11.2361 7.19998 10.5036 7.50341 9.96346 8.04351C9.42336 8.58362 9.11993 9.31616 9.11993 10.08C9.11993 13.44 7.67993 14.4 7.67993 14.4H16.3199C16.3199 14.4 14.8799 13.44 14.8799 10.08Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.8305 16.32C12.7461 16.4655 12.625 16.5862 12.4792 16.6702C12.3335 16.7541 12.1683 16.7983 12.0001 16.7983C11.8319 16.7983 11.6667 16.7541 11.5209 16.6702C11.3752 16.5862 11.2541 16.4655 11.1697 16.32" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0">
                <rect width="11.52" height="11.52" fill="white" transform="translate(6.23999 6.23999)"/>
                </clipPath>
                </defs>
              </svg>
              {{ $filters.dateFormat(h.created_at, 'full') }}
            </div>
            {{ h.text }}
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        @ionInfinite="fetchNotif"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent, IonPage, IonRefresher, IonRefresherContent,
  IonList, IonItem, IonLabel,
  IonInfiniteScroll, IonInfiniteScrollContent,
} from '@ionic/vue';
import Header from '@/components/headerPage'
export default {
  components: {
    IonContent,
    IonPage,
    Header,
    IonRefresher, IonRefresherContent,
    IonList, IonItem, IonLabel,
    IonInfiniteScroll, IonInfiniteScrollContent
  },
  data () {
    return {
      isDisabled: false,
      notifications: [],
      page: 1
    }
  },
  created() {
    this.fetchNotif()
  },
  methods: {
    async doRefresh(e) {
      this.page = 1;
      this.notifications = []
      this.isDisabled = false
      await this.fetchNotif()
      e.target.complete();
    },
    async fetchNotif(e) {
      let data = await this.$api.get('/api/notification', { params: { page: this.page }})
      if(data.meta.last_page == this.page) {
        this.isDisabled = true;
      }

      data.data.forEach(c => {
        this.notifications.push(c)
      })

      if(e && e.type == 'ion-infinite')
        e.target.complete();

      this.page++
    },
    async read (n) {
      await this.$api.get(`/api/notification/${n.id}`);
      this.$router.push(n.action)
    }
  }
}
</script>

<style lang="scss">
.sub-label {
  font-size: 12px;
  color: #545454;
  margin-top: 5px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
}

.notification {
  .item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
    white-space: normal;
  }

  .read {
    opacity: 0.5;
  }
}
</style>
